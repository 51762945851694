import React from "react"
import clx from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  image: {
    width: "100%",
    height: "100%",
    minHeight: "92vh",
    backgroundColor: theme.palette.primary.main,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}))

const BackgroundImageDots = ({ children, className }) => {
  const classes = useStyles()
  const classNames = clx(classes, className)

  const data = useStaticQuery(graphql`
    query BackgroundImage {
      placeholderImage: file(
        relativePath: { eq: "background_blue_dots_opacity60.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
          gatsbyImageData(quality: 95, layout: FULL_WIDTH)
        }
      }
    }
  `)

  if (data && data.placeholderImage.childImageSharp.fluid) {
    return (
      <BackgroundImage
        Tag="section"
        className={classNames}
        fluid={data.placeholderImage.childImageSharp.fluid}
        alt="background image"
        backgroundColor="#040e18"
      >
        {children}
      </BackgroundImage>
    )
  }
  return children
}

export default BackgroundImageDots
