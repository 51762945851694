const primaryColor = [
  // See https://material.io/inline-tools/color/
  "#0f4c81", // primary simpleen
  "#0a355a",
  "#0f4c81",
  "#3f6f9a",
  "#b2e0f4", // 100
  "#54b7e3", // 300

  {
    /*"#9c27b0", // 500 purple
  "#ab47bc", // 400
  "#8e24aa", // 600
  "#af2cc5", // 
  "#e1bee7", // 100
  "#ba68c8" // 300
*/
  },
]
const secondaryColor = ["#ff6e40"] // orange of simpleen
const warningColor = [
  "#ff9800",
  "#ffa726",
  "#fb8c00",
  "#ffa21a",
  "#fcf8e3",
  "#faf2cc",
  "#ffe0b2",
  "#ffb74d",
]
const dangerColor = [
  "#f44336",
  "#ef5350",
  "#e53935",
  "#f55a4e",
  "#f2dede",
  "#ebcccc",
  "ef9a9a",
  "#ef5350",
]
const successColor = [
  "#4caf50",
  "#66bb6a",
  "#43a047",
  "#5cb860",
  "#dff0d8",
  "#d0e9c6",
  "#a5d6a7",
  "#66bb6a",
]
const infoColor = [
  "#00acc1",
  "#26c6da",
  "#00acc1",
  "#00d3ee",
  "#d9edf7",
  "#c4e3f3",
  "#b2ebf2",
  "#4dd0e1",
]
const roseColor = ["#e91e63", "#ec407a", "#d81b60", "#f8bbd0", "#f06292"]
const grayColor = [
  "#999",
  "#3C4858",
  "#eee",
  "#343434",
  "#585858",
  "#232323",
  "#ddd",
  "#6c757d",
  "#333",
  "#212121",
  "#777",
  "#D2D2D2",
  "#AAA",
  "#495057",
  "#e5e5e5",
  "#555",
  "#f9f9f9",
  "#ccc",
  "#444",
  "#f2f2f2",
  "#89229b",
  "#c0c1c2",
  "#9a9a9a",
  "#f5f5f5",
  "#505050",
  "#1f1f1f",
]

const blackColor = "#000"
const whiteColor = "#FFF"

const hexToRgb = input => {
  input = input + ""
  input = input.replace("#", "")
  let hexRegex = /[0-9A-Fa-f]/g
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.")
  }
  if (input.length === 3) {
    let first = input[0]
    let second = input[1]
    let last = input[2]
    input = first + first + second + second + last + last
  }
  input = input.toUpperCase(input)
  let first = input[0] + input[1]
  let second = input[2] + input[3]
  let last = input[4] + input[5]
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  )
}

const primaryBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(" +
    hexToRgb(primaryColor[0]) +
    ", 0.28), 0 4px 20px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 7px 8px -5px rgba(" +
    hexToRgb(primaryColor[0]) +
    ", 0.2)",
}

const containerFluid = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  width: "100%",
}

const container = {
  ...containerFluid,
  "@media (min-width: 576px)": {
    maxWidth: "540px",
  },
  "@media (min-width: 768px)": {
    maxWidth: "720px",
  },
  "@media (min-width: 992px)": {
    maxWidth: "960px",
  },
  "@media (min-width: 1200px)": {
    maxWidth: "1140px",
  },
}

const title = {
  color: grayColor[1],
  textDecoration: "none",
  fontWeight: "600",
  marginTop: "30px",
  marginBottom: "25px",
  minHeight: "32px",
  fontFamily: '"Open Sans", sans-serif', // `"Roboto Slab", "Times New Roman", serif`
}

const cardTitle = {
  "&, & a": {
    ...title,
    marginTop: ".625rem",
    marginBottom: "0.75rem",
    minHeight: "auto",
  },
}

const sectionDescription = {
  marginTop: "130px",
}

const description = {
  color: grayColor[0],
}

export {
  container,
  containerFluid,
  hexToRgb,
  blackColor,
  dangerColor,
  grayColor,
  roseColor,
  infoColor,
  warningColor,
  successColor,
  primaryColor,
  secondaryColor,
  whiteColor,
  primaryBoxShadow,
  title,
  cardTitle,
  description,
  sectionDescription,
}
